import React from 'react';
import Layout from '../components/Layout';
import Auth from 'utils/Auth/Auth';
import { navigate } from 'gatsby';
import logger from 'utils/logger';
import useComponentDidMount from 'utils/Auth/useComponentDidMount';

const auth = new Auth();

class Callback extends React.Component {
  componentDidMount() {
    auth.handleAuthentication();
    setTimeout(() => {
      navigate('/account/profile');
    }, 1500);
  }

  render() {
    return (
      <div>Loading...</div>
    );
  }
}

export default Callback;